<template>
  <div class="search-class">
    <div class="top-search">
      <el-input v-model="yym" placeholder="请输入预约码">
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="search()"
        ></el-button>
      </el-input>
    </div>
    <div class="ret-data" v-if="retdata">
      <div class="row-class">
        <div class="label-class">姓名：</div>
        <div class="text-class">{{ retdata.sname }}</div>
      </div>
      <div class="row-class">
        <div class="label-class">预约码：</div>
        <div class="text-class state-class">{{ retdata.yym }}</div>
      </div>
      <div class="row-class">
        <div class="label-class">日期：</div>
        <div class="text-class">{{ retdata.day }}</div>
      </div>
      <div class="row-class">
        <div class="label-class">时间段：</div>
        <div class="text-class">{{ retdata.time }}</div>
      </div>
      <div class="row-class">
        <div class="label-class">状态：</div>
        <div
          class="text-class state-class"
          :style="{
            color:
              retdata.state == -1
                ? '#E6A23C'
                : retdata.state == 0
                ? '#F56C6C'
                : '#67C23A',
          }"
        >
          {{ getstate(retdata.state) }}
        </div>
      </div>
      <vue-qr
        :text="qrdata.url"
        :margin="0"
        colorDark="#409EFF"
        colorLight="#fff"
        :logoScale="0.3"
        :size="300"
      ></vue-qr>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import md5 from "js-md5";
export default {
  components: {
    vueQr,
  },
  data() {
    return {
      retdata: false,
      yym: "",
      qrdata: {
        url: window.location.href.split("/#/")[0] + "/#/search?s=",
      },
    };
  },
  mounted() {
    if (this.$route.query.s) {
      this.$axios
        .get("/carapi/getret.php?s=" + this.$route.query.s)
        .then((res) => {
          if (res.data.length > 0) {
            this.retdata = res.data[0];
            this.yym = res.data[0].yym;
            // if (this.retdata.retnum.length < 2) {
            //   this.retdata.retnum = "0" + this.retdata.retnum;
            // }
            this.qrdata.url =
              window.location.href.split("/#/")[0] +
              "/#/search?s=" +
              md5(this.retdata.sphonenum +""+ this.retdata.retnum);
            // this.qrdata.url = this.qrdata.url + md5(res.data[0].sphonenum);
          }
        });
    }
  },
  methods: {
    getstate(code) {
      if (code == -1) {
        return "正在审核";
      } else if (code == 0) {
        return "审核未通过";
      } else {
        return "审核通过";
      }
    },
    search() {
      if (this.yym) {
        this.$axios.get("/carapi/searchyym.php?yym=" + this.yym).then((res) => {
          if (res.data.length > 0) {
            this.retdata = res.data[0];
            // if (this.retdata.retnum.length < 2) {
            //   this.retdata.retnum = "0" + this.retdata.retnum;
            // }
            this.qrdata.url =
              window.location.href.split("/#/")[0] +
              "/#/search?s=" +
              md5(this.retdata.sphonenum +""+ this.retdata.retnum);
          } else {
            this.$message.error("查无此信息，请确认信息是否正确！");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-class {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .top-search {
    width: 96%;
    margin-top: 10px;
  }
  .ret-data {
    width: 96%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .row-class {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      .label-class {
        width: 100px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 900;
      }
      .text-class {
        width: calc(100% - 150px);
      }
      .state-class {
        font-weight: 900;
      }
    }
  }
}
</style>